import "../scss/index.scss";

class App {
  constructor() {
    this.createComponents();
  }

  async createComponents() {
    /**
     * HomeSliderAgenda
     *
     */
    const homeSliderAgenda = [
      ...document.querySelectorAll("[data-components-slider-home-agenda]"),
    ];
    if (homeSliderAgenda.length > 0) {
      const { default: HomeSliderAgenda } = await import(
        "./components/HomeSliderAgenda"
      );
      new HomeSliderAgenda();
    }
    /**
     * HomeSliderTalents
     *
     */
    const homeSliderTalents = [
      ...document.querySelectorAll("[data-components-slider-home-talents]"),
    ];
    if (homeSliderTalents.length > 0) {
      const { default: HomeSliderTalents } = await import(
        "./components/HomeSliderTalents"
      );
      new HomeSliderTalents();
    }
    /**
     * AnimationObserver
     *
     */
    const animationObserver = [
      ...document.querySelectorAll("[data-components-observer]"),
    ];
    if (animationObserver.length > 0) {
      const { default: AnimationObserver } = await import(
        "./components/AnimationObserver"
      );
      new AnimationObserver();
    }
    /**
     * GroupeShowMore
     *
     */
    const groupeShowMore = [
      ...document.querySelectorAll("[data-components-show-more]"),
    ];
    if (groupeShowMore.length > 0) {
      const { default: GroupeShowMore } = await import(
        "./components/GroupeShowMore"
      );
      new GroupeShowMore();
    }
    /**
     * GroupeSliderHistory
     *
     */
    const groupeSliderHistory = [
      ...document.querySelectorAll("[data-components-slider-history]"),
    ];
    if (groupeSliderHistory.length > 0) {
      const { default: GroupeSliderHistory } = await import(
        "./components/GroupeSliderHistory"
      );
      new GroupeSliderHistory();
    }
    /**
     * Accordion
     *
     */
    const accordion = [
      ...document.querySelectorAll("[data-components-accordion]"),
    ];
    if (accordion.length > 0) {
      const { default: Accordion } = await import("./components/Accordion");
      new Accordion();
    }
    /**
     * AnchorLink
     *
     */
    const anchor = [
      ...document.querySelectorAll("[data-components-anchor-link]"),
    ];
    if (anchor.length > 0) {
      const { default: AnchorLink } = await import("./components/AnchorLink");
      new AnchorLink();
    }
    /**
     * Charts
     *
     */
    const chartsStock = [
      ...document.querySelectorAll("[data-components-ChartsStocktock]"),
    ];
    if (chartsStock.length > 0) {
      const { default: ChartsStock } = await import("./components/ChartsStock");
      new ChartsStock();
    }
    /**
     * FinanceSliderAgenda
     *
     */
    const financeSliderAgenda = [
      ...document.querySelectorAll("[data-components-slider-finance-agenda]"),
    ];
    if (financeSliderAgenda.length > 0) {
      const { default: FinanceSliderAgenda } = await import(
        "./components/FinanceSliderAgenda"
      );
      new FinanceSliderAgenda();
    }
    /**
     * Popin
     *
     */
    const popin = [
      ...document.querySelectorAll("[data-components-popin-toggle]"),
    ];
    if (popin.length > 0) {
      const { default: Popin } = await import("./components/Popin");
      new Popin();
    }
    /**
     * Contact
     *
     */
    const contact = [
      ...document.querySelectorAll("[data-components-contact-form]"),
    ];
    if (contact.length > 0) {
      const { default: Contact } = await import("./components/Contact");
      new Contact();
    }
    /**
     * Charts
     *
     */
    const charts = [...document.querySelectorAll("[data-components-charts]")];
    if (charts.length > 0) {
      const { default: Charts } = await import("./components/Chart");
      new Charts();
    }
    /**
     * Dropdown
     *
     */
    const dropdown = [
      ...document.querySelectorAll("[data-components-dropdown]"),
    ];
    if (dropdown.length > 0) {
      const { default: Dropdown } = await import("./components/Dropdown");
      new Dropdown();
    }
    /**
     * Tabs
     *
     */
    const tabs = [...document.querySelectorAll("[data-components-tabs]")];
    if (tabs.length > 0) {
      const { default: Tabs } = await import("./components/Tabs");
      new Tabs();
    }
    /**
     * TabsFilter
     *
     */
    const tabsFilter = [...document.querySelectorAll("[data-components-tabs-filter]")];
    if (tabsFilter.length > 0) {
      const { default: TabsFilter } = await import("./components/TabsFilter");
      new TabsFilter();
    }
    /**
     * Map
     *
     */
    const engagementsMap = [
      ...document.querySelectorAll("[data-components-map]"),
    ];
    if (engagementsMap.length > 0) {
      const { default: EngagementsMap } = await import(
        "./components/EngagementsMap"
      );
      new EngagementsMap();
    }
    /**
     * Engagements slider
     *
     */
    const engagementsSlider = [
      ...document.querySelectorAll("[data-components-slider-engagements]"),
    ];
    if (engagementsSlider.length > 0) {
      const { default: EngagementsSlider } = await import(
        "./components/EngagementsSlider"
      );
      new EngagementsSlider();
    }
    /**
     * Gallery with filter
     *
     */
    const galleryFilter = [
      ...document.querySelectorAll("[data-components-gallery-filter]"),
    ];
    if (galleryFilter.length > 0) {
      const { default: GalleryFilter } = await import(
        "./components/GalleryFilter"
      );
      new GalleryFilter();
    }
    /**
     * Slider engagements
     *
     */
    const engagementsSliderEngages = [
      ...document.querySelectorAll("[data-components-slider-engages]"),
    ];
    if (engagementsSliderEngages.length > 0) {
      const { default: EngagementsSliderEngages } = await import(
        "./components/EngagementsSliderEngages"
      );
      new EngagementsSliderEngages();
    }
    /**
     * Slider customers
     *
     */
    const marquesSliderCustomers = [
      ...document.querySelectorAll("[data-components-slider-customers]"),
    ];
    if (marquesSliderCustomers.length > 0) {
      const { default: MarquesSliderCustomers } = await import(
        "./components/MarquesSliderCustomers"
      );
      new MarquesSliderCustomers();
    }
    /**
     * Slider pillars
     *
     */
    const marquesSliderPillars = [
      ...document.querySelectorAll("[data-components-slider-pillars]"),
    ];
    if (marquesSliderPillars.length > 0) {
      const { default: MarquesSliderPillars } = await import(
        "./components/MarquesSliderPillars"
      );
      new MarquesSliderPillars();
    }
    /**
     * Slider ambitions
     *
     */
    const marquesSliderAmbitions = [
      ...document.querySelectorAll("[data-components-slider-ambitions]"),
    ];
    if (marquesSliderAmbitions.length > 0) {
      const { default: MarquesSliderAmbitions } = await import(
        "./components/MarquesSliderAmbitions"
      );
      new MarquesSliderAmbitions();
    }
    /**
     * Slider Experience
     *
     */
    const marquesSliderExperience = [
      ...document.querySelectorAll("[data-components-slider-experience]"),
    ];
    if (marquesSliderExperience.length > 0) {
      const { default: MarquesSliderExperience } = await import(
        "./components/MarquesSliderExperience"
      );
      new MarquesSliderExperience();
    }
    /**
     * Slider Photos
     *
     */
    const cmsSliderPhotos = [
      ...document.querySelectorAll("[data-components-slider-photos]"),
    ];
    if (cmsSliderPhotos.length > 0) {
      const { default: CmsSliderPhotos } = await import(
        "./components/CmsSliderPhotos"
        );
      new CmsSliderPhotos();
    }
    /**
     * Slider Cards
     *
     */
    const cmsSliderCards = [
      ...document.querySelectorAll("[data-components-slider-cards]"),
    ];
    if (cmsSliderCards.length > 0) {
      const { default: CmsSliderCards } = await import(
        "./components/CmsSliderCards"
        );
      new CmsSliderCards();
    }
    /**
     * Slider Cards Text
     *
     */
    const cmsSliderCardsText = [
      ...document.querySelectorAll("[data-components-slider-cards-text]"),
    ];
    if (cmsSliderCardsText.length > 0) {
      const { default: CmsSliderCardsText } = await import(
        "./components/CmsSliderCardsText"
        );
      new CmsSliderCardsText();
    }
    /**
     * Readmore
     *
     */
    const readMore = [
      ...document.querySelectorAll("[data-components-readmore]"),
    ];
    if (readMore.length > 0) {
      const { default: Readmore } = await import("./components/Readmore");
      new Readmore();
    }
    /**
     * UploadFile
     *
     */
    const uploadFile = [
      ...document.querySelectorAll("[data-components-upload-file]"),
    ];
    if (uploadFile.length > 0) {
      const { default: UploadFile } = await import("./components/UploadFile");
      new UploadFile();
    }
    /**
     * Slider Chronologie
     *
     */
     const sliderChronologie = [
      ...document.querySelectorAll("[data-components-slider-chronologie]"),
    ];
    if (sliderChronologie.length > 0) {
      const { default: SliderChronologie } = await import(
        "./components/SliderChronologie"
      );
      new SliderChronologie();
    }
    /**
     * Switch Wording
     *
     */
     const switchWording = [
      ...document.querySelectorAll("[data-component-change-wording]"),
    ];
    if (switchWording.length > 0) {
      const { default: SwitchWording } = await import(
        "./components/SwitchWording"
      );
      new SwitchWording();
    }
    /**
     * Select2
     *
     */
    const select2 = [
      ...document.querySelectorAll("[data-components-select2]"),
    ];
    if (select2.length > 0) {
      const { default: Select2 } = await import(
        "./components/Select2"
        );
      new Select2();
    }
    /**
     * Breadcrumbs
     *
     */
    const breadcrumbs = [
      ...document.querySelectorAll("[data-components-breadcrumbs]"),
    ];
    if (breadcrumbs.length > 0) {
      const { default: Breadcrumbs } = await import(
        "./components/Breadcrumbs"
        );
      new Breadcrumbs();
    }
    /**
     * Menu
     *
     */
    const fixHeight = [
      ...document.querySelectorAll("[data-components-fixHeight]"),
    ];
    if (fixHeight.length > 0) {
      const { default: FixHeight } = await import(
        "./components/FixHeight"
        );
      new FixHeight();

    }
    /**
     * NavSidebar
     *
     */
    const navSidebar = [
      ...document.querySelectorAll("[data-components-nav-sidebar]"),
    ];
    if (navSidebar.length > 0) {
      const { default: NavSidebar } = await import(
        "./components/NavSidebar"
        );
      new NavSidebar();
    }
    /**
     * Footer menu
     *
     */
    const footer = [
      ...document.querySelectorAll("[data-components-footer]"),
    ];
    if (footer.length > 0) {
      const { default: Footer } = await import(
        "./components/Footer"
        );
      new Footer();
    }
    /**
     * Menu
     *
     */
         const menu = [
          ...document.querySelectorAll("[data-component-menu]"),
        ];
        if (menu.length > 0) {
          const { default: Menu } = await import(
            "./components/Menu"
          );
          new Menu();
       
      }
  }
}
window.addEventListener("DOMContentLoaded", () => {
  new App();
});
